import { App, AppStatus, AppStatusEnum } from './types';

const transformAppStatus = (ext: boolean, status: AppStatus) => {
  if (ext) return AppStatusEnum.External;
  if (status.installed && status.ready) return AppStatusEnum.Installed;
  if (status.installed && !status.ready) return AppStatusEnum.Installing;
  return AppStatusEnum.NotInstalled;
};

export const transformApp = (response: any): App => {
  const assetSlug = !response.external
    ? `/icons/${response.slug}.svg`
    : `/icons/${response.slug.replace('ext-', '')}.svg`;
  const markdownSlug = !response.external
    ? `/markdown/${response.slug}.md`
    : `/markdown/${response.slug.replace('ext-', '')}.md`;
  return {
    id: response.id ?? '',
    name: response.name ?? '',
    slug: response.slug ?? '',
    external: response.external ?? '',
    status: transformAppStatus(response.external, response.status),
    url: response.url,
    automaticUpdates: response.automatic_updates,
    assetSrc: assetSlug,
    markdownSrc: markdownSlug,
  };
};

export const transformAppRequest = (data: App) => {
  return {
    automatic_updates: data.automaticUpdates,
    configuration: data.configuration,
  };
};

export const transformInstallAppRequest = (data: App) => {
  return {
    url: data.url,
    configuration: data.configuration,
  };
};
