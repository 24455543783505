import React, { Fragment, useEffect, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { MenuIcon, XIcon, ExclamationIcon } from '@heroicons/react/outline';
import { performApiCall } from 'src/services/api';
import { useAuth } from 'src/services/auth';
import { useApps } from 'src/services/apps';
import md5 from 'md5';
import Gravatar from 'react-gravatar';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import _ from 'lodash';

import { UserModal } from '../UserModal';

const navigation = [
  { name: 'Dashboard', to: '/dashboard', requiresAdmin: false },
  { name: 'Users', to: '/users', requiresAdmin: true },
  { name: 'Apps', to: '/apps', requiresAdmin: true },
  { name: 'System resources', to: '/resources', requiresAdmin: true },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

function filterNavigationByDashboardRole(isAdmin: boolean) {
  if (isAdmin) {
    return navigation;
  }

  return navigation.filter((item) => !item.requiresAdmin);
}

export interface Environment {
  HYDRA_PUBLIC_URL: string;
  KRATOS_PUBLIC_URL: string;
  TELEPRESENCE: boolean;
}

const defaultEnvironment: Environment = {
  HYDRA_PUBLIC_URL: 'error-failed-to-load-env-from-backend',
  KRATOS_PUBLIC_URL: 'error-failed-to-load-env-from-backend',
  TELEPRESENCE: false,
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface HeaderProps {}

const Header: React.FC<HeaderProps> = () => {
  const [environment, setEnvironment] = useState(defaultEnvironment);
  const [currentUserModal, setCurrentUserModal] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const { logOut, currentUser, isAdmin } = useAuth();

  const { pathname } = useLocation();

  const { apps, loadApps } = useApps();
  useEffect(() => {
    loadApps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let active = true;
    async function loadEnvironment() {
      const result = await performApiCall({
        path: '/environment',
      });
      if (!active) {
        return;
      }
      setEnvironment(result.data);
    }
    loadEnvironment();
    return () => {
      active = false;
    };
  }, []);

  const currentUserModalOpen = (id: any) => {
    setCurrentUserId(id);
    setCurrentUserModal(true);
  };

  const currentUserModalClose = () => {
    setCurrentUserModal(false);
    setCurrentUserId(null);
  };

  const navigationItems = filterNavigationByDashboardRole(isAdmin);

  const signOutUrl = `${environment.HYDRA_PUBLIC_URL}/oauth2/sessions/logout`;
  const kratosSettingsUrl = `${environment.KRATOS_PUBLIC_URL}/self-service/settings/browser`;

  const adminTag = isAdmin ? (
    <span className="inline-flex items-center rounded-md bg-primary-100 px-2 py-1 text-xs font-medium text-gray-500 ring-1 ring-inset ring-gray-300">
      Admin
    </span>
  ) : null;

  // banner that only shows if telepresence is active
  const underConstruction = environment.TELEPRESENCE ? (
    <div className="shadow bg-pink-500 h-6 text-xs text-white font-semibold uppercase flex justify-center items-center gap-2">
      <ExclamationIcon className="h-4" />
      <span className="h-4 m-0 p-0">Development environment</span>
      <ExclamationIcon className="h-4" />
    </div>
  ) : null;

  return (
    <>
      {underConstruction}
      <Disclosure as="nav" className="bg-white shadow relative z-10">
        {({ open }) => (
          <div className="relative">
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <Link to="/" className="flex-shrink-0 flex items-center">
                    <img className="stackspin-logo-small block lg:hidden" src="/icons/logo-small.svg" alt="Stackspin" />
                    <img className="stackspin-logo hidden lg:block" src="/icons/logo.svg" alt="Stackspin" />
                  </Link>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    {/* Current: "border-primary-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    {navigationItems.map((item) => (
                      <Link
                        key={item.name}
                        to={item.to}
                        className={clsx(
                          'border-primary-50 hover:border-gray-300 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium',
                          {
                            'border-primary-500 hover:border-primary-500 text-gray-500 inline-flex items-center px-1 pt-1 text-sm font-medium':
                              pathname.includes(item.to),
                          },
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>

                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  {adminTag}
                  {/* Profile dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">
                        <span className="sr-only">Open user menu</span>
                        <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500 overflow-hidden">
                          <Gravatar
                            md5={md5(currentUser?.email ?? 'no-user') || undefined}
                            size={32}
                            rating="pg"
                            protocol="https://"
                          />
                        </span>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={() => currentUserModalOpen(currentUser?.id)}
                              className={classNames(
                                active ? 'bg-gray-100 cursor-pointer' : '',
                                'block px-4 py-2 text-sm text-gray-700 cursor-pointer',
                              )}
                            >
                              Configure profile
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href={kratosSettingsUrl}
                              className={classNames(
                                active ? 'bg-gray-100 cursor-pointer' : '',
                                'block px-4 py-2 text-sm text-gray-700 cursor-pointer',
                              )}
                            >
                              Authentication settings
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              onClick={() => logOut()}
                              href={signOutUrl}
                              className={classNames(
                                active ? 'bg-gray-100 cursor-pointer' : '',
                                'block px-4 py-2 text-sm text-gray-700 cursor-pointer',
                              )}
                            >
                              Sign out
                            </a>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-4 space-y-1">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.to}
                    className={clsx(
                      'border-transparent text-gray-500 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium',
                      {
                        'bg-primary-50 border-primary-400 text-primary-700 block pl-3 pr-4 py-2': pathname.includes(
                          item.to,
                        ),
                      },
                    )}
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>

      {currentUserModal && (
        <UserModal
          open={currentUserModal}
          onClose={currentUserModalClose}
          userId={currentUserId}
          setUserId={_.noop}
          apps={apps}
        />
      )}
    </>
  );
};

export default Header;
